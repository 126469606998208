import { Module, Action, getModule, Mutation } from "vuex-module-decorators";
import store from "../index";
import { APIBatch, Batch, QueryTypes } from "../../types";
import router from "../../router";
import { ListingPageStore } from "@pasabi/ui-patterns";
import { $api } from "../../api/api";
import { RawLocation } from "vue-router";

const DEFAULT_N_BATCHES = 500;

/**
 * Returns an identifier for a batch using their dates so we can have a unique key for
 * all the batches related with the same dates
 */
export const batchDatesKey = (batch: Batch) =>
  `${batch.batchStartDate.toISOString()}:${batch.batchEndDate.toISOString()}`;

export const batchName = ({
  batchEndDate,
  batchStartDate,
}: {
  batchEndDate: Date;
  batchStartDate: Date;
}) => {
  const start = batchStartDate.toLocaleString(undefined, {
    day: "numeric",
    month: "short",
    hour: "numeric",
    minute: "numeric",
    year: batchStartDate.getFullYear() != new Date().getFullYear() ? "numeric" : undefined,
  });

  const end = batchEndDate.toLocaleString(undefined, {
    day: "numeric",
    month: "short",
    hour: "numeric",
    minute: "numeric",
    year: "numeric",
  });

  return `${start} - ${end}`;
};

export const parseAPIBatch = (batch: APIBatch): Batch => {
  const batchStartDate = new Date(batch.batchStartDate);
  const batchEndDate = new Date(batch.batchEndDate);
  const dateCreated = new Date(batch.dateCreated);

  return {
    ...batch,
    batchId: batch._id,
    batchStartDate,
    batchEndDate,
    dateCreated,
    batchName: batchName({
      batchStartDate,
      batchEndDate,
    }),
  };
};

const QUERIES_SORTING_ARRAY = [
  QueryTypes.Query_Spam,
  QueryTypes.Query_Scam,
  QueryTypes.Query_PositiveBiasCrossIPCombo,
  QueryTypes.Query_PositiveBiasCrossIPComboDemo,
  QueryTypes.Query_PositiveBiasLocalIPCAT,
  QueryTypes.Query_NegativeBiasCrossIPCombo,
  QueryTypes.Query_SecondaryQuery,
  QueryTypes.Query_FlaggedDataCAT,
];

@Module({ dynamic: true, name: "Batches", store, namespaced: true })
class Batches extends ListingPageStore<APIBatch> {
  _selectedBatch: Batch | null = null;
  _apiPath = "/batches";

  get api() {
    return $api;
  }

  get selectedBatch(): Batch | undefined {
    return this._selectedBatch || undefined;
  }

  get selectedBatchId(): string {
    return this.selectedBatch?.batchId || "";
  }

  get batches(): Batch[] {
    return (this.items || [])
      .map(parseAPIBatch)
      .filter((batch) => {
        if (!process.env.VUE_APP_ALLOWED_QUERY_TYPES) {
          return true;
        }

        const allowedQueries = process.env.VUE_APP_ALLOWED_QUERY_TYPES.split(",");

        return allowedQueries.includes(batch.query);
      })
      .sort((a, b) => {
        const indexA = QUERIES_SORTING_ARRAY.indexOf(a.query);
        const indexB = QUERIES_SORTING_ARRAY.indexOf(b.query);
        // if a is not found in order, give it a higher index value
        if (indexA === -1) return 1;
        // if b is not found in order, give a lower index value
        if (indexB === -1) return -1;
        // otherwise, sort based on index in order
        return indexA - indexB;
      });
  }

  get queryBatchByDate() {
    return this.batches.reduce(
      (acc, cur) => {
        const datesKey = batchDatesKey(cur);

        return {
          ...acc,
          [datesKey]: {
            name: batchName(cur),
            batches: [...(acc[datesKey]?.batches || []), cur],
          },
        };
      },
      {} as Record<string, { batches: Batch[]; name: string }>,
    );
  }

  get queryBatchByDateAndQuery() {
    return (dateKey: string, query: Batch["query"]): Batch | undefined => {
      const batches = this.queryBatchByDate[dateKey]?.batches || [];

      const index = batches.findIndex((b) => b.query == query);

      return batches[index];
    };
  }

  get batchById() {
    return (batchId: string) => {
      return this.batches.find((b) => b.batchId == batchId);
    };
  }

  @Mutation
  setSelectedBatch(batch: Batch) {
    this._selectedBatch = batch;
  }

  @Action
  async updateSelectedBatch({
    batchId,
    redirectTo,
  }: {
    batchId: string;
    redirectTo?: RawLocation;
  }) {
    const batch = this.batches.find((b) => b.batchId === batchId);
    if (batch) {
      this.setSelectedBatch(batch);
      router.push(
        redirectTo || {
          params: { ...router.currentRoute.params, batchId: batchId },
        },
      );
    } else {
      throw new Error("Batch that doesn't exist was selected");
    }
  }

  @Action
  async updateSelectedBatchNoRedirect(value: string) {
    const batch = this.batches.find((b) => b.batchId === value);
    if (batch) {
      this.setSelectedBatch(batch);
    } else {
      throw new Error("Batch that doesn't exist was selected");
    }
  }

  get selectedBatchIsMostRecent(): boolean {
    if (this._selectedBatch === null) {
      return false;
    }
    return this.batchIsMostRecent(this._selectedBatch.batchId);
  }

  get batchIsMostRecent(): (batchId: string) => boolean {
    return (batchId): boolean => {
      const batch = this.batches.find((b) => b.batchId == batchId);

      const mostRecentBatch = [...this.batches].sort((a, b) =>
        b.dateCreated > a.dateCreated ? 1 : -1,
      )[0];

      if (batch) {
        return (
          mostRecentBatch.batchStartDate.toString() == batch.batchStartDate.toString() &&
          mostRecentBatch?.batchEndDate.toString() == batch.batchEndDate.toString()
        );
      }

      return false;
    };
  }

  @Action
  async fetchBatches(batchId?: string) {
    await this.fetchItems({
      params: {
        limit:
          (process.env.VUE_APP_N_BATCHES && parseInt(process.env.VUE_APP_N_BATCHES)) ||
          DEFAULT_N_BATCHES,
      },
    });

    const batch = this.batches.find((b) => b.batchId === batchId);

    if (batchId && batch) {
      this.setSelectedBatch(batch);
    } else {
      router.push(`/${this.batches[0].batchId}${router.currentRoute.fullPath}`);
    }
  }
}

export const BatchesStore = getModule(Batches);
