
import { IGraphNode } from "@pasabi/ui-blossom/types/interfaces/graph";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { UiButton, UiPreloader } from "@pasabi/ui-components";
import { RawLocation } from "vue-router";
import { BusinessDetailsStore } from "../../store/modules/BusinessDetails";
import { BusinessGraph } from "../../types";
import { UiNodeOverview } from "@pasabi/ui-blossom";

@Component({
  components: {
    UiButton,
    UiPreloader,
    UiNodeOverview,
  },
})
export default class BusinessGraphNode extends Vue {
  store = BusinessDetailsStore;

  @Prop({ required: true }) node!: IGraphNode;
  @Prop({ required: true }) type!: "overlay" | "overview";

  get businessId() {
    return this.node.properties.business_id;
  }

  @Watch("suspectId", { immediate: true })
  suspectIdChanged() {
    if (this.businessId) {
      this.loadData();
    }
  }

  loadData() {
    this.store.fetchItemAssociation({ id: this.businessId, key: "graph" });
  }

  get properties(): { label: string; value: string | number; size?: number }[] {
    if (this.business) {
      return [
        {
          label: this.$t("graph.business.fields.id").toString(),
          value: this.business.business_id,
          size: 100,
        },
        { label: this.$t("graph.business.fields.tier").toString(), value: this.business.tier },
        {
          label: this.$t("graph.business.fields.profileScore").toString(),
          value: `${Math.round(this.business.profilesPercentage * 100)}%`,
        },
        {
          label: this.$t("graph.business.fields.reviewScore").toString(),
          value: `${Math.round(this.business.reviewsPercentage * 100)}%`,
        },
        {
          label: this.$t("graph.business.fields.action").toString(),
          value: this.$t(`filters.actionNew.${this.business.actionNew}`).toString(),
        },
        {
          label: this.$t("graph.business.fields.reviewsCount").toString(),
          value: this.business.reviewsCount,
        },
        {
          label: this.$t("graph.business.fields.profilesCount").toString(),
          value: this.business.profilesCount,
        },
        {
          label: this.$t("graph.business.fields.ipsCount").toString(),
          value: this.business.ipsCount,
        },
      ];
    }
    return [];
  }

  get isLoading() {
    return this.store.isLoadingAssociation({ id: this.businessId, key: "graph" });
  }

  get isLoaded() {
    return this.store.isAsssociationLoaded({ id: this.businessId, key: "graph" });
  }

  get loadingError() {
    return this.store.associationLoadingError({ id: this.businessId, key: "graph" });
  }

  get business(): BusinessGraph {
    return this.store.associationData({ id: this.businessId, key: "graph" });
  }

  get businessLocation(): RawLocation {
    return {
      name: "business>details",
      params: {
        ...this.$router.currentRoute.params,
        queryType: this.business.query,
        businessId: this.businessId,
      },
    };
  }
}
