import { RouteConfig } from "vue-router";
const RouterViewPassthrough = () => import("../views/RouterViewPassthrough.vue");
const BusinessOverview = () => import("../views/businesses/BusinessOverviewTab.vue");
const BusinessDetails = () => import("../views/businesses/BusinessDetails.vue");
const BusinessesListing = () => import("../views/businesses/BusinessesListing.vue");
const BusinessReviews = () => import("../views/businesses/BusinessReviewsTab.vue");
const BusinessIPs = () => import("../views/businesses/BusinessIPsTab.vue");
const BusinessISPs = () => import("../views/businesses/BusinessISPsTab.vue");
const BusinessProfiles = () => import("../views/businesses/BusinessProfilesTab.vue");
const BusinessRelatedBusinesses = () =>
  import("../views/businesses/BusinessRelatedBusinessesTab.vue");
const BusinessNotes = () => import("../views/businesses/BusinessNotesTab.vue");
const BusinessGraph = () => import("../views/businesses/BusinessGraph.vue");

const routes: Array<RouteConfig> = [
  {
    path: "businesses/",
    component: RouterViewPassthrough,
    meta: {
      breadcrumb: "Businesses",
      breadcrumbOverrideName: "BusinessesListing",
    },
    children: [
      {
        path: "",
        name: "BusinessesListing",
        component: BusinessesListing,
        meta: {
          breadcrumb: "Dashboard",
        },
      },
      {
        path: ":businessId",
        component: BusinessDetails,
        name: "business>details",
        meta: {
          breadcrumb: function breadcrumb() {
            return this.$store.getters["BusinessDetails/businessName"](
              this.$route.params.businessId,
            );
          },
          breadcrumbOverrideName: "business>overview",
        },
        redirect: { name: "business>overview" },
        children: [
          {
            path: "",
            name: "business>overview",
            component: BusinessOverview,
            meta: {
              breadcrumb: "Overview",
            },
          },
          {
            path: "reviews",
            name: "business>reviews",
            component: BusinessReviews,
            meta: {
              breadcrumb: "Reviews",
            },
          },
          {
            path: "profiles",
            name: "business>profiles",
            component: BusinessProfiles,
            meta: {
              breadcrumb: "Profiles",
            },
          },
          {
            path: "businesses",
            name: "business>relatedbusinesses",
            component: BusinessRelatedBusinesses,
            meta: {
              breadcrumb: "Related Businesses",
            },
          },
          {
            path: "ips",
            name: "business>ips",
            component: BusinessIPs,
            meta: {
              breadcrumb: "IPs",
            },
          },
          {
            path: "isps",
            name: "business>isps",
            component: BusinessISPs,
            meta: {
              breadcrumb: "ISPs",
            },
          },
          {
            path: "notes",
            name: "business>notes",
            component: BusinessNotes,
            meta: {
              breadcrumb: "Notes",
            },
          },
          {
            path: "graph",
            name: "business>graph",
            component: BusinessGraph,
            meta: {
              breadcrumb: "Graph",
              plainLayout: true,
            },
          },
        ],
      },
    ],
  },
];

export default routes;
